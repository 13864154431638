<template>
    <div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;min-height:670px">
			<div class="title"> 项目合同工期 </div>
			<div class="line"></div>
            <el-col :span="24">
                <el-table :row-class-name="tableRowClassName" ref="dataListTable" row-key="recNo"
                class="dataListTable" :data="dataList" header-align="center" border
                style="width: 100%;margin-bottom: 20px;" header-cell-class-name="dataListTHeader" 
                :cell-style="{ padding: '0px' }">
                    <el-table-column prop="projectNo" label=" " width="200" align="center">
                    </el-table-column>
                    <el-table-column prop="deliverDate" label="合同工期" width="200" align="center">
                        <template slot-scope="scope">
                            <span v-if="!getDisabled(scope.row.recNo)">
                                {{scope.row.deliverDate }}
                            </span>
                            <el-date-picker v-model="scope.row.deliverDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" 
                                v-if="getDisabled(scope.row.recNo)" style="width: 175px;">
                            </el-date-picker>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center">
                        <template slot-scope="scope">
                            <span v-if="!getDisabled(scope.row.recNo)">
                                {{scope.row.remark }}
                            </span>
                            <el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
                                v-model="scope.row.remark"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="" width="110" align="center">
                        <template slot-scope="scope">
                            <el-button v-preventReClick type="text" size="small"
                                @click="updateHandle(scope.row.recNo)"
                                v-if="!updateFlg">修改</el-button>
                            <el-button v-preventReClick type="text" size="small"
                                @click="saveContractDuration(scope.row)"
                                v-if="updateFlg && scope.row.recNo == updateRecNo">保存</el-button>
                            <el-button v-preventReClick type="text" size="small"
                                @click="cancelUpdateHandle()"
                                v-if="updateFlg && scope.row.recNo == updateRecNo">取消</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-card>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
import Sortable from "sortablejs";
export default {
	data() {
		return {
			updateFlg: false,
			form: {},
			workNo: $common.getItem("workNo"),
			dataList: [],
            updateRecNo: ''
		};
	},
	components: {
	},
	mounted() {
        this.refreshContractDuractions()
        this.dragSort()
		// this.getDataList();
	},
	methods: {
        getDisabled(val) {
            if (this.updateRecNo === val && this.updateFlg) {
			    return true;
            } 
            return false;
        },
        dragSort() {
			const el = this.$refs.dataListTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
			this.sortable = Sortable.create(el, {
				onEnd: e => {	//onEnd是结束拖拽时触发，onUpdate是列表内元素顺序更新的时候触发，更多请看文末配置项
					//e.oldIndex为拖动一行原来的位置，e.newIndex为拖动后新的位置
					const targetRow = this.dataList.splice(e.oldIndex, 1)[0];
					this.dataList.splice(e.newIndex, 0, targetRow);
					let recNo = this.dataList[e.newIndex].recNo;//拖动行的id
					// 后端发送请求排序
					this.$http({
						url: this.$store.state.httpUrl + "/business/contractduration/sort",
						method: "POST",
						data: this.$http.adornData({
							records: this.dataList,
							workNo: $common.getItem("workNo"),
						}),
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						}
					});
				}
			})
		},
        refreshContractDuractions() {
            this.$http({
				url: this.$store.state.httpUrl + "/business/contractduration/refreshContractDuractions/" + $common.getItem("workNo"),
				method: 'post',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.getDataList()
				}
			})
        },
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/contractduration/list/" + $common.getItem("workNo"),
				method: 'get',
				params: this.$http.adornParams({
				})
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
                    this.updateFlg = false;
                    this.updateRecNo = '';
				}
			})
		},
		updateHandle(recNo) {
			this.updateFlg = true;
            this.updateRecNo = recNo;
		},
        cancelUpdateHandle () {
            this.updateFlg = false;
            this.updateRecNo = ''
        },
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
        saveContractDuration(val) {
            this.$http({
				url: this.$store.state.httpUrl + "/business/contractduration/update",
				method: 'post',
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: 1500,
                    });
					this.getDataList()
				}
			})
        }
	}
};
</script>
<style scoped>

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}
</style>
